.Achievements {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  list-style: none;
  margin: 0;
}

.Achievement {
  display: block;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 40px;
  filter: grayscale(100%);
  opacity: 0.9;
  pointer-events: none;
  box-shadow: 0px 10px 20px 1px rgba(78,163,154,0.2);
  @include mq($from: medium) {
    width: calc(33.333% - 20px);
    margin-right: 20px;
  }
  @include mq($from: xlarge) {
    width: 320px;
    margin-right: 40px;
  }

  &--unlocked {
    filter: none;
    opacity: 1;
    pointer-events: all;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__illustration {
    display: flex;
    align-items: center;
    height: 220px;
    background-color: transparentize(theme-color(green), 0.8);
    overflow: hidden;
    svg {
      display: block;
      width: 100%;
      height: auto;
      padding: 20px;
      overflow: visible;
    }
  }

  &__inner {
    padding: 30px 20px 34px 20px;
    border-top: none;
    border-bottom: none;
  }

  &__title {
    @include build-font('paragraph-big');
    line-height: 1;
    margin-bottom: 10px;
    text-align: center;
  }

  &__text {
    @include build-font('paragraph-small');
    margin-bottom: 0;
    text-align: center;
  }

  &__footer {
    margin-top: auto;
    .Button {
      @include build-font('paragraph-small');
      margin: 0;
      border-radius: 0;
      width: 100%;
      &:hover {
        transform: none;
      }
    }
  }

  #HighFive,#HighFiveTwo,#HighFiveThree {
    padding: 35px;
  }

  .Loader {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    transform: translateX(40px);
  }

}