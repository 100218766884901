.Modal {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 10;

  transition-duration: 250ms;
  transition-property: opacity,visibility;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;

  opacity: 0;
  visibility: hidden;

  &--open {
    opacity: 1;
    visibility: visible;
    .Modal__surface {
      transform: scale(1);
      opacity: 1;
      pointer-events: auto !important;
    }
    .Modal__scrim {
      opacity: 1;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height: 100%;
    pointer-events: none;
  }

  &__surface {
    pointer-events: none !important;
    position: relative;
    min-width: 320px;
    max-width: 650px;
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    pointer-events: auto;
    overflow-y: auto;
    background-color: theme-color(white);
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0,0,0,.12);
    transform: scale(0.8);
    opacity: 0;
    transition: transform 200ms ease, opacity 200ms ease;
    @include mq($from: medium) {
      min-width: 650px;
    }
  }

  &__top {
    padding: 20px;
    border-bottom: 1px solid #e0e2e4;
    p {
      margin: 0;
      line-height: 1;
    }
  }

  &__content {
    padding: 20px;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    padding: 0;
    margin: 0;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 26px;
    opacity: 0.7;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }

  &__scrim {
    position: absolute;
    opacity: 1;
    z-index: -1;
    background-color: rgba(0,0,0,.32);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

}