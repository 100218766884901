//
// Reset
//
@mixin typography-reset-links {
  color: inherit;
  text-decoration: none;
}

//
// Default Styles
//
@mixin typography-links {
  a {
    @include typography-reset-links;
  }

  // Links in content
  p {
    a {
      text-decoration: underline;
      color: theme-color(orange);
    }
  }
}
