.LangSwitcher {
  position: relative;
  cursor: pointer;
  &:hover label {
    background-color: transparentize(theme-color(green), 0.8);
  }
}

.LangSwitcher label {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  transition: background-color 200ms ease;
}

.LangSwitcher label svg {
  fill: #1a202c;
}

.LangSwitcher label svg:first-child {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  fill: #1a202c;
}

.LangSwitcher label svg:last-child {
  width: 12px;
  height: 12px;
  margin-left: 20px;
  margin-top: 2px;
  fill: #1a202c;
}

.LangSwitcher select {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  appearance: none;
  border: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

// Login/signup styling
.LangSwitcher--login {
  max-width: 200px;
  margin: 20px auto 0 auto;
  border-radius: 4px;
  border: 1px solid #CDCEE4;
  label {
    height: 42px;
  }
  label svg:last-child {
    margin-left: auto;
  }
}

// Inside signup flow desktop
.SignUpInstructions__why + .LangSwitcher--login {
  display: none;
  @include mq($from: large) {
    display: block;
    margin: 20px 0 0 0;
  }
}

// Inside signup flow mobile
.SignUpSteps + .LangSwitcher__wrapper {
  margin-top: 20px;
  padding: 24px 0;
  background-color: #f7f7fc;
  .LangSwitcher--login {
    margin: 0 auto;
  }
  @include mq($from: large) {
    display: none;
  }
}
