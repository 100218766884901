.Module {
  display: block;
  margin-bottom: 40px;
  max-width: 1000px;

  &--transparent {
    max-width: none;
    .Module__title {
      border: none;
      background: transparent;
    }
    .Module__content {
      border: none;
      background: transparent;
    }
  }

  &--wide {
    max-width: 1920px;
  }

  &__title {
    padding: 20px 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: none;
    background-color: lighten(theme-color(green), 52%);
    h3 {
      @include build-font('paragraph-big');
      margin: 0;
      line-height: 1;
      font-weight: 400;
    }
  }

  &__content {
    padding: 10px 24px 20px 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

}