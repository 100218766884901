.Dashboard {
  display: block;
  min-height: 100vh;
  padding-left: 72px;
  padding-top: 60px;
  background-color: lighten(theme-color(green), 49.5%);
  @include mq($until: medium) {
    padding-left: 0;
    padding-top: 40px;
  }
  &__inner {
    padding: 40px;
    @include mq($until: medium) {
      padding: 40px 16px;
    }
  }
}