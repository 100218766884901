//
// Font family
//
$theme-font-family: 'Lato', Arial, Helvetica, Geneva, sans-serif !default;

//
// Reset
//
@mixin typography-reset-headlines {
  font-family: $theme-font-family;
  margin-top: 0;
  margin-bottom: 2rem;
}

//
// Default Styles
//
@mixin typography-headlines {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include typography-reset-headlines;
  }

  h1,
  .headline-1 {
    @include build-font('headline-1');
  }

  h2,
  .headline-2 {
    @include build-font('headline-2');
  }

  h3,
  .headline-3 {
    @include build-font('headline-3');
  }

  h4,
  .headline-4 {
    @include build-font('headline-4');
  }
 
  h5, 
  h6,
  .headline-5,
  .headline-6 {
    @include build-font('headline-3');
  }
}
