.Form {
  margin-bottom: 60px;

  input[type="text"], input[type="email"], input[type="tel"], input[type="password"], input[type="url"], input[type="number"], input[type="search"], textarea, select, .DayPickerInput input {
    display: block;
    width: 100%;
    @include build-font(paragraph);
    padding: 0 16px;
    margin: 0 0 20px 0;
    height: 44px;
    border-radius: 2px;
    background: #FCFCFF;
    border: 1px solid #CDCEE4;
    &.disabled {
      color: #868686;
      cursor: not-allowed;
    }
    &::placeholder { 
      color: rgb(117, 117, 117);
    }
    &.missing-input {
      border-color: red;
    }
  }

  .ReactPasswordStrength {
    input[type="password"] {
      margin: 0;
      border: none;
    }
  }

  .ReactPasswordStrength {
    font-size: initial;
    color: initial;
    border: 1px solid #CDCEE4;
    font-family: inherit;
    border-radius: 2px;
    margin-bottom: 5px;
  }

  .ReactPasswordStrength-strength-desc {
    width: 120px;
  }

  textarea {
    min-height: 160px;
    line-height: 1.3;
    padding: 10px 16px;
  }

  .DayPickerInput {
    display: block;
    margin-bottom: 20px;
    input {
      margin-bottom: 0;
    }
  }
  
  .Form__checkbox input[type="checkbox"] {
    top: 5px;
    left: 10px;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
    padding: 0;
    &:checked + label:before {
      background: theme-color(green);
    }
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 10px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
        transform: rotate(45deg);
    }
  }
  
  .Form__checkbox input[type="checkbox"] + label {
    position: relative;
    cursor: pointer;
    padding-left: 28px;
    @include mq($from: medium) {
      padding-left: 32px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: inline-block;
      border: 2px solid theme-color(green);
      border-radius: 3px;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
    }
  }
  
  .checkbox-wrapper {
    position: relative;
    margin-bottom: 30px;
  }

  &__radioWrapper, &__multicheckWrapper {
    display: flex;
    margin: 0 0 20px 0;
    @include mq($until: medium) {
      flex-wrap: wrap;
    }
    &.missing-input {
      border: 1px solid red;
    }
    
    label {
      display: block;
      width: 100%;
      text-align: center;
      cursor: pointer;
      span {
        display: block;
        border: 1px solid #CDCEE4;
        padding: 10px 30px;
        box-sizing: content-box;
        transition: background-color 200ms ease, color 200ms ease;
      }
      &:not(:last-child) {
        span {
          @include mq($from: medium) {
            border-right: none;
          }
        }
      }
      &:last-child {
        input[type="radio"], input[type="checkbox"] {
          &:checked ~ span {
            border-right-color: darken(theme-color(orange), 15%);
          }
        }
      }
      &:hover {
        span {
          background-color: transparentize(theme-color(orange), 0.6);
        }
      }
    }

    &--width33 {
      flex-wrap: wrap;
      label {
        width: 33.333%;
        margin-bottom: 0;
        @include mq($until: medium) {
          width: 50%;
        }
        span {
          padding: 12px 10px;
        }
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          span {
            border-bottom: none;
          }
        }
        &:nth-child(3) {
          span {
            border-right: 1px solid #CDCEE4;
          }
          input[type="radio"], input[type="checkbox"] {
            &:checked ~ span {
              border-right-color: darken(theme-color(orange), 15%);
            }
          }
        }
      }
    }

    input[type="radio"], input[type="checkbox"] {
      display: none;
      &:checked ~ span {
        background-color: darken(theme-color(orange), 5%);
        color: theme-color(white);
        border-left-color: darken(theme-color(orange), 15%);
        border-top-color: darken(theme-color(orange), 15%);
        border-bottom-color: darken(theme-color(orange), 15%);
      }
    }

    + .tooltip {
      margin-top: -20px;
    }

  }

  select {
    position: relative;
    width: 100%;
    color: theme-color(black);
    cursor: pointer;
    outline: none;
    appearance: none;
    &:invalid {
      color: rgb(117, 117, 117);
    }
  }

  &__selectWrapper {
    position: relative;
    &::after {
      z-index: 3;
      content: " ";
      position: absolute;
      top: 52%;
      right: 20px;
      transform: translateY(-50%);
      border-top: 7px solid #abacc1;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      pointer-events: none;
    }
  }

  &__indicator {
    display: flex;
    align-items: center;
    background: #FCFCFF;
    border-radius: 2px;
    margin: 0 0 20px 0;
    border: 1px solid #CDCEE4;
    > div {
      padding: 12px 16px;
      &:first-child {
        border-right: 1px solid #CDCEE4;
        span {
          opacity: 0.5;
        }
      }
      &:last-child {
        width: 100%;
        background-color: util-color(warning-bg);
        color: util-color(warning-text);
        span:last-child {
          display: none;
        }
      }
    }
    &--valid {
      > div {
        &:last-child {
          background-color: util-color(success-bg);
          color: util-color(success-text);
          span:last-child {
            display: block;
          }
          span:first-child {
            display: none;
          }
        }
      }
    }
  }

  &__errorMessage,
  &__successMessage,
  &__notifyMessage {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    p {
      display: block;
      margin: 0;
    }
    &--showMessage {
      height: auto;
      visibility: visible;
      opacity: 1;
      border: 1px solid;
      padding: 20px 14px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &__errorMessage {
    background-color: util-color(error-bg);
    border-color: util-color(error-border);
    color: util-color(error-text);
  }

  &__successMessage {
    background-color: util-color(success-bg);
    border-color:  util-color(success-border);
    color: util-color(success-text);
  }

  &__notifyMessage {
    background-color: theme-color(darkgray);
    border-color:  theme-color(darkishgray);
    color: darken(theme-color(darkishgray), 50%);
  }

  &__notification {
    border: 1px solid #ffeeba;
    padding: 20px 14px;
    background-color: #fff3cd;
    color: #856404;
    p {
      margin: 0;
    }
  }

  .Accordion__label {
    display: flex;
    opacity: 1;
    margin-bottom: 0;;
  }

}