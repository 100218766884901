.Profile {
  max-width: 1100px;
  margin: 0 auto;
  border-radius: 12px;

  &__top {
    position: relative; 
    display: flex;
    align-items: center;
    padding: 20px 30px;
    border: 1px solid #dfe2e4;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    background-color: rgba(78, 163, 154, 0.2);
    @include mq($until: medium) {
      display: block;
    }

    a {
      display: block;
      width: 30px;
      height: 30px;
      padding: 8px;
      border: 1px solid theme-color(green);
      border-radius: 50%;
      transition: background-color 200ms ease;
      &:hover {
        background-color: theme-color(green);
        svg {
          fill: theme-color(white);
        }
      }
      svg {
        fill: theme-color(green);
        display: block;
        width: 100%;
        height: 100%;
        transition: fill 200ms ease;
      }
    }
  }

  &__badge {
    position: absolute;
    top: 20px;
    right: 20px;
    .Tooltip {
      display: inline-block;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    svg {
      fill: theme-color(green);
      width: 30px;
      height: 30px;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    display: block;
    margin: 0 30px 0 0;
    width: 180px;
    height: 180px;
    background-color: lightgray;
    cursor: pointer;
    @include mq($until: medium) {
      width: 140px;
      height: 140px;
      margin: 0 0 20px 0;
    }
    &:hover {
      p {
        opacity: 1;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
    p {
      position: absolute;
      left: 0;
      bottom: 0;
      @include build-font(paragraph-note);
      display: block;
      width: 100%;
      margin: 0;
      padding: 12px 0 16px 0;
      text-align: center;
      background-color: transparentize(theme-color(black), 0.45);
      color: theme-color(white);
      opacity: 0;
      transition: opacity 200ms ease;
    }
    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      padding: 0;
      margin: 0;
      border: none;
    }
  }

  &__newimage {
    margin-right: 30px;
    .Button {
      display: block;
      margin-bottom: 10px;
    }
    &--loading {
      opacity: 0.6;
      cursor: wait;
      .Button {
        cursor: wait;
      }
    }
  }

  &__inner {
    padding: 0 20px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #dfe2e4;
    min-height: 300px;
    @include mq($until: medium) {
      padding: 0 16px 20px;
    }
  }

  &__name, &__title, &__location {
    @include build-font('paragraph-small');
    margin: 0 0 10px 0;
    line-height: 1;
    text-align: left;
  }

  &__title {
    @include mq($until: medium) {
      line-height: 1.2;
    }
  }

  &__title, &__location {
    font-weight: normal;
  }

  &__location {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__nav {
    margin-left: -21px;
    margin-right: -21px;
    @include mq($until: medium) {
      margin-left: -17px;
      margin-right: -17px;
    }
    ul {
      display: flex;
      li {
        width: 100%;
        &:last-child {
          button {
            border-right: 1px solid #CDCEE4;
          }
        }
      }
    }
    button {
      display: block;
      width: 100%;
      height: 100%;
      @include build-font('paragraph');
      line-height: 1;
      margin: 0;
      padding: 18px;
      text-align: center;
      border: 1px solid #CDCEE4;
      border-right: none;
      transition: background-color 200ms ease, color 200ms ease;
      cursor: pointer;
      @include mq($until: medium) {
        @include build-font('paragraph-note');
        padding: 8px 5px;
      }
      &:hover {
        background-color: transparentize(theme-color(green), 0.6);
      }
      &[data-active="true"] {
        background-color: theme-color(green);
        color: theme-color(white);
        border-left-color:theme-color(green);
        border-top-color: theme-color(green);
        border-bottom-color: theme-color(green);
      }
    }
  }

  &__page {
    position: relative;
    padding-top: 30px;
    margin-bottom: 0;
  }

  &__headline {
    @include build-font('headline-4');
    font-weight: 400;
    line-height: 1;
    margin: 12px 0 38px 0;
  }

  p {
    line-height: 1;
  }

  &__subheadline {
    font-weight: bold;
    margin: 0 0 9px 0;
  }

  &__info {
    margin: 0 0 5px 0;
    a {
      color: theme-color(black);
      text-decoration: none;
      &:hover {
        color: theme-color(green);
        text-decoration: underline;
      }
    }
  }

  &__text {
    line-height: 1.5;
    @include build-font('paragraph-small');
    margin: 16px 0;
    padding: 24px;
    max-width: 700px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 1px 5px rgba(0,0,0,.06);
    white-space: pre-wrap;
    &--none {
      color: gray;
    }
  }

  &__inlinelist {
    li {
      display: inline;
      margin: 0 0 5px 0;
      @include build-font('paragraph-small');
    }
  }

  hr {
    margin: 20px 0;
    border: 1px solid #e0e0e0;
  }

  &__preferences {
    .Profile__headline {
      margin-bottom: 10px;
    }
  }

  &__preferences, &__fieldgroup {
    hr {
      margin: 22px 0;
      border: none;
    }
  }

  &__getCompetencies {
    padding: 20px; 
    background-color: #f1f1f1;
    .Button {
      margin-bottom: 20px;
    }
    span {
      display: block;
      margin-bottom: 10px;
    }
    code {
      display: block;
      margin-bottom: 20px;
      padding: 10px;
      background-color: #fff;
    }
  }

  &__editbtn, &__save {
    position: absolute;
    right: 0;
    top: 30px;
    @include mq($until: medium) {
      position: relative;
      top: unset;
    }
  }

  &__viewbtn {
    
  }

  &__editbtn {
    min-width: 0;
    @include mq($until: medium) {
      margin-left: auto;
      display: block;
    }
    span {
      @include mq($until: medium) {
        display: none;
      }
    }
  }

  &__save {
    @include mq($until: medium) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    .Button:first-child {
      margin-right: 10px;
    }
  }

  .Form {
    margin-bottom: 0;
  }

  .Form__errorMessage, .Form__successMessage {
    ul {
      margin-bottom: 0;
      li {
        line-height: 1;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .Form__successMessage--showMessage, .Profile__save + .Form__errorMessage--showMessage {
    margin-top: 70px;
    margin-bottom: 30px;
  }

  .Form__notification {
    margin-bottom: 30px;
  }

  div label {
    margin-bottom: 0;
  }

  input, .Form__radioWrapper, .Form__selectWrapper, .Form__multicheckWrapper {
    max-width: 550px;
  }

  textarea {
    max-width: 720px;
    min-height: 130px;
  }

  .Match__criterias {
    > span {
      margin-bottom: 10px;
    }
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__fieldgroup {
    margin: 60px 0;
    .Profile__text .tooltip {
      margin-bottom: 0;
    }
  }

  &__infogroup {
    .tooltip, .Profile__subheadline {
      &:last-child {
        margin-bottom: 40px;
      }
    }
  }

}