figure {
  display: block;
  margin: 0;
  padding: 0;
}

.image {
  figcaption {
    max-width: 425px;
  }
}