.Information {
  margin: -10px -24px -20px -24px;
  li {
    border-bottom: 1px solid #e0e2e4;
    &:last-child {
      border-bottom: none;
    }
  }
  li a {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    transition: background-color 200ms ease;
    @include mq($until: medium) {
      padding: 12px 20px;
    }
    &:hover {
      background-color: transparentize(theme-color(orange), 0.8);
    }
  }
  svg {
    display: block;
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-right: 20px;
  }
  p {
    margin: 0;
    line-height: 1.2;
    @include mq($until: medium) {
      @include build-font('paragraph-small');
    }
  }
}