.ImageUpload {
  max-width: 1000px;
  margin: 0 auto;

  &__input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border: 2px dashed theme-color(green);
    border-radius: 2px;
    min-height: 320px;
    margin-bottom: 30px !important;
    transition: background-color 300ms ease;
    cursor: pointer;
    &:hover {
      background-color: transparentize(theme-color(orange), 0.7);
    }
    svg {
      display: block;
      margin: 0 auto 20px auto;
      width: 50px;
      height: 50px;
      fill: theme-color(green);
    }
    span {
      @include build-font('paragraph');
      display: block;
      text-align: center;
      margin: 0;
      line-height: 1;
      color: theme-color(green),
    }
    input {
      top: 0;
      position: fixed!important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px,1px,1px,1px);
    }
  }

  .ReactCrop {
    display: block;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .ReactCrop__crop-selection {
    border-radius: 50%;
  }

  &__preview {
    display: block;
    max-width: 350px;
    min-width: 350px;
    min-height: 350px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: center;
    &--success {
      display: block;
      .Form__successMessage--showMessage {
        margin-top: 0;
      }
      .Button {
        display: block;
        margin: 0 auto;
      }
    }
    &--error {
      flex-wrap: wrap;
      .Form__errorMessage--showMessage {
        margin-top: 0;
        width: 100%;
      }
      &:last-child {
        margin-left: 20px;
      }
    }
    .Button {
      &:first-child {
        margin-right: 20px;
      }
    }
    .Button--gray {
      cursor: not-allowed;
      &:hover {
        transform: none;
      }
    }
  }

}