//====================================
// Containers and wrappers
//====================================

body {
  position: relative;
  background-color: #fff;
}

.main-wrapper {
  padding-top: $header-height;
}

.max-width {
  max-width: 750px;
}

.max-width-small {
  max-width: 405px;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.module {
  display: block;
  padding: 20px 24px;
  border-radius: 4px;
  margin-bottom: 40px;
  .module-title {
    @include build-font('paragraph-big');
    margin: 0 0 10px 0;
    display: block;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: -24px;
    margin-right: -24px;
    line-height: 1;
    border-bottom: 1px solid #e0e2e4;
    font-weight: 400;
  }
}

.module-small {
  display: inline-block;
  padding: 14px 24px;
}

.outer-padding-x {
  padding-left: $outer-padding;
  padding-right: $outer-padding;
  @include mq($from: medium) {
    padding-left: $outer-padding-medium;
    padding-right: $outer-padding-medium;
  }
  @include mq($from: large) {
    padding-left: $outer-padding-large;
    padding-right: $outer-padding-large;
  }
  @include mq($from: xxlarge) {
    padding-left: $outer-padding-xlarge;
    padding-right: $outer-padding-xlarge;
  }
}

.outer-padding-y {
  padding-top: $outer-padding-y;
  padding-bottom: $outer-padding-y;
  @include mq($from: medium) {
    padding-top: $outer-padding-y-medium;
    padding-bottom: $outer-padding-y-medium;
  }
  @include mq($from: large) {
    padding-top: $outer-padding-y-large;
    padding-bottom: $outer-padding-y-large;
  }
  @include mq($from: xxlarge) {
    padding-top: $outer-padding-y-xxlarge;
    padding-bottom: $outer-padding-y-xxlarge;
  }
}