.SignUp, .Login {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  padding: 20px 0 0 0;
  @include mq($from: medium) {
    flex-wrap: nowrap;
    padding: 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 300px;
    background-color: theme-color(white);
    @include mq($from: medium) {
      min-width: 500px;
      max-width: 620px;
      width: 100%;
      min-height: 0;
    }
  }

  &__inner {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
  }

  &__form {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 30px;
    background: theme-color(white);
    box-shadow: 0px 15px 75px rgba(139, 140, 185, .25);
    border-radius: 2px;
    @include mq($from: medium) {
      width: calc(100% - 80px);
      padding: 36px;
    }
    form {
      display: block;
      width: 100%;
    }
    .Form {
      margin-bottom: 0;
    }
  }

  .Logo {
    margin-bottom: 20px;
    @include mq($from: medium) {
      margin-bottom: 40px;
    }
  }

  &__title {
    @include build-font('paragraph-big');
    text-align: center;
    font-weight: 400;
  }

  &__alternate {
    @include build-font('paragraph-small');
    margin: 12px 0 0 0;
    text-align: center;
    color: theme-color(darkergray);
    line-height: 1;
    a {
      color: theme-color(green);
    }
    &:first-child {
      margin-top: 20px;
    }
  }

  &__media {
    position: relative;
    width: 100%;
    overflow: hidden;
    @include mq($until: medium) {
      min-height: 300px;
    }
    img {
      position: absolute;
      min-height: 101%;
      min-width: 101%;
      transform: translate(-50%,-50%);
      left: 50%;
      top: 50%;
      z-index: 1;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .05;
      background-color: #4faa9a;
      z-index: 2;
    }
  }

  input[type=submit] {
    width: 100%;
  }

}

.Login input[type=email] {
  margin-bottom: 12px;
}

.SignUpStep .Login {
  min-height: auto;
}