.Message {
  display: flex;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 20px;
  }
  &--own {
    justify-content: flex-end;
    .Message__message {
      order: 1;
      background-color: transparentize(theme-color(orange), 0.5);
    }
    .Message__image {
      order: 2;
      margin: 0 0 0 10px;
    }
    .Message__timestamp {
      left: unset;
      right: 0;
    }
  }
  &--cm {
    flex-direction: column;
    align-items: center;

    .Message__message {
      background-color: #e0e2e4;
      p {
        text-align: center;
      }
    }
    .Message__image {
      margin-bottom: 0px;
    }
    .Message__timestamp {
      display: none;
    }

  }
  &__image {
    position: relative;
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin: 0 10px 0 0;
    border-radius: 50%;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
  }
  &__message {
    position: relative;
    padding: 10px;
    background-color: transparentize(theme-color(green), 0.5);
    border-radius: 4px;
    max-width: 450px;
    p {
      @include build-font('paragraph-small');
      margin: 0;
      white-space: pre-line;
      &:last-child {
        margin: 0;
      }
    }
  }
  &__timestamp {
    font-size: 12px;
    line-height: 1;
    margin: 0;
    position: absolute; 
    bottom: -18px;
    left: 0;
    opacity: 0.5;
    min-width: 106px;
    min-width: 120px;
  }
}