.CompetenceSelector {
  margin-bottom: 40px;
  > ul {
    @include mq($from: large) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  &__category {
    &--selected {
      .Accordion__label {
        background-color: theme-color(green);
        color: theme-color(white);
        .Accordion__icon div {
          background-color: theme-color(white);
        }
      }
    }
    @include mq($from: large) {
      width: calc(50% - 10px);
    }
  }
  &__competencies {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.Competence {
  display: block;
  width: calc(50% - 3px);
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  &__btn {
    width: 100%;
    min-width: auto;
    text-align: left;
    justify-content: space-between;
    line-height: 1.3;
    color: theme-color(black);
    border-color: theme-color(darkgray);
    span {
      word-break: break-word;
    }
  }
  &--selected {
    .Competence__btn {
      background-color: theme-color(green);
      color: theme-color(white);
      border-color: theme-color(green);
      svg {
        fill: theme-color(white);
      }
    }
  }
}

.SignUpStep {
  .Competence {
    width: 100%;
  }
}