.Requests {
  &__none {
    margin: 5px 0 0 0;
    line-height: 1;
    @include mq($until: medium) {
      line-height: 1.4;
    }
  }
  &__type {
    @include build-font('paragraph-note');
    display: block;
    line-height: 1;
    margin: 0;
    padding: 12px 0;
    border-right: none;
    border-left: none;
    border-top: none;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: lighten(theme-color(green), 52%);
    &:first-child {
      margin-top: -10px;
    }
  }
  &__many {
    @include build-font('paragraph-note');
    display: block;
    margin: 0;
    margin-left: -24px;
    margin-right: -24px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid #e0e2e4;
    a {
      color: theme-color(green); 
      text-decoration: underline;
    }
  }
}

.Request {

  @include mq($from: medium) {
    min-width: 500px;
  }

  &__top {
    padding: 20px 20px 16px 20px;
    border: 1px solid #dfe2e4;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    background-color: transparentize(theme-color(green), 0.85);

    > div:first-child {
      display: flex;
      align-items: center;
      @include mq($until: medium) {
        flex-wrap: wrap;
      }
    }

    > div:last-child {
      margin-top: 20px;
    }

    > div > a {
      display: block;
      margin-right: 40px;
      @include mq($until: medium) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  &__credentials {
    width: 100%;
  }

  .Match__linkedinbtn {
    margin-top: 8px;
  }

  // &__linkedin {
  //   display: block;
  //   width: 30px;
  //   height: 30px;
  //   padding: 8px;
  //   margin: 10px 0 0 0;
  //   border: 1px solid theme-color(green);
  //   border-radius: 50%;
  //   transition: background-color 200ms ease;
  //   &:hover {
  //     background-color: theme-color(green);
  //     svg {
  //       fill: theme-color(white);
  //     }
  //   }
  //   svg {
  //     fill: theme-color(green);
  //     display: block;
  //     width: 100%;
  //     height: 100%;
  //     transition: fill 200ms ease;
  //   }
  // }
  
  &__image {
    display: block;
    position: relative;
    padding: 0;
    overflow: hidden;    
    border-radius: 50%;
    margin: 0;
    width: 180px;
    height: 180px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
  }

  &__inner {
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #dfe2e4;
    overflow: hidden;
  }

  &__name {
    @include build-font('paragraph-small');
    line-height: 1.2;
    margin-bottom: 10px;
  }

  &__title, &__location {
    @include build-font('paragraph-small');
    margin: 0 0 10px 0;
    line-height: 1.1;
  }

  &__title, &__location {
    font-weight: normal;
  }

  &__location {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__message__title, &__status p {
    @include build-font('paragraph-small');
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;
  }

  &__message {
    p {
      &:last-child {
        margin-bottom: 0;
      }
      span {
        color: gray;
      }
    }
  }

  &__status {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    padding: 20px;
    margin-top: 20px;
    border-top: 1px solid #dfe2e4;
    background-color: #fcfefe;
    p {
      &:last-child {
        margin-bottom: 0;
        font-weight: normal;
      }
    }
    div + div {
      margin-top: 20px;
      .nomessage {
        color: gray;
      }
      p:last-child {
        line-height: 1.5;
      }
    }
  }
  
  &__footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .Loader {
      width: 36px;
      height: 36px;
      margin-left: auto;
      margin-right: auto;
      transform: translateX(7px);
    }
    .Button--green--outline:hover {
      .Loader circle {
        fill: #fff;
      }
    }
    &--loading {
      .Button {
        cursor: wait;
      }
    }
    &--actionTaken {
      display: block;
      .Form__successMessage--showMessage {
        margin-top: 0;
        margin-bottom: 20px;
      }
      .Button {
        margin: 0 auto;
        display: block;
      }
    }
    .Request__ignore {
      margin-right: 20px;
      margin-bottom: 30px;
      position: relative;
      &::after, &::before {
        position: absolute;
        bottom: -34px;
        left: 0;
        width: 100%;
        text-align: center;
        content: 'Du får mulighed for at tilføje en besked';
        font-size: 12px;
        line-height: 1.2;
        color: gray;
      }
      &::before {
        display: none;
        content: 'You will have the option to leave a message';
      }
      &--english {
        &::before {
          display: block;
        }
        &::after {
          display: none;
        }
      }
      + .Button {
        margin-bottom: 30px;
      }
    }
  }

  &__ignore__wrapper {
    width: 100%;
    .Form {
      margin-bottom: 20px;
    }
    .Button {
      width: 100%;
    }
  }

  &__archive {
    .Button {
      margin: 0;
    }
  }

}