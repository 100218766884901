.Conversations {
  &__noconversations {
    margin: 0;
  }
}

.Conversation {
  max-width: 1100px;
  margin: 0 auto;
  &__top {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    border: 1px solid#e0e2e4;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: rgba(78, 163, 154, 0.25);
    @include mq($until: medium) {
      padding: 14px 16px;
      justify-content: center;
    }
    > a {
      display: block;
      width: 26px;
      height: 26px;
      @include mq($until: medium) {
        display: none;
      }
      @include mq($until: medium) {
        width: 18px;
        height: 18px;
      }
      svg {
        display: block;
        height: 100%;
        width: 100%;
        transition: fill 200ms ease;
      }
      &:hover {
        svg {
          fill: theme-color(orange);
        }
      }
    }
  }
  &__person {
    display: flex;
    align-items: center;
    @include mq($until: medium) {
      padding-left: 20px;
    }
    a {
      display: block;
      position: relative;
    }
  }
  &__image {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;    
    border-radius: 50%;
    background-color: lightgray;
    @include mq($until: medium) {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
  }
  &__image__badge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -10px;
    left: -10px;
    width: 30px;
    height: 30px;
    background-color: theme-color(white);
    border-radius: 50%;
    @include mq($until: medium) {
      width: 24px;
      height: 24px;
    }
    .Tooltip {
      width: 16px;
      height: 16px;
      @include mq($until: medium) {
        width: 12px;
        height: 12px;
      }
    }
    svg {
      display: block;
      fill: theme-color(green);
      width: 100%;
      height: 100%;
    }
  }
  &__image__badge__type--mentor {
    transform: scale(1.1);
  }
  &__title, &__name {
    @include build-font('paragraph');
    text-align: left;
    margin: 0;
    line-height: 1;
    @include mq($until: medium) {
      @include build-font('paragraph-note');
    }
  }
  &__name {
    margin-bottom: 5px;
  }
  &__lastonline {
    display: block;
    @include build-font('paragraph-tiny');
    line-height: 1.1;
    margin: 10px 0 0 0;
    opacity: 0.5;
    @include mq($until: medium) {
      margin-top: 5px;
    }
  }
  &__title {
    font-weight: normal;
  }
  &__inner {
    padding: 30px 20px 20px 20px;
    border: 1px solid#e0e2e4;
    border-top: 0;
    border-bottom: 0;
    background-color: theme-color(white);
    height: calc(100vh - 344px);
    overflow: auto;
    @include mq($until: medium) {
      height: calc(100vh - 265px);
      padding: 16px 18px;
    }
  }
  &__input {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: theme-color(white);
    border: 1px solid#e0e2e4;
    border-top-width: 2px;
    @include mq($until: medium) {
      padding: 14px 16px;
      padding-right: 88px;
    }
    &--focused {
      border-top-color: transparentize(theme-color(orange), 0.4);
    }
    textarea {
      resize: none;
      display: block;
      width: 100%;
      max-width: none;
      border: none;
      padding: 5px 0;
      @include mq($until: medium) {
        padding: 0;
      }
      &:focus {
        outline: 0;
      }
    }
    .Button {
      min-width: 120px;
      padding: 10px 14px;
      @include mq($until: medium) {
        position: absolute;
        right: 0;
        top: -2px;
        height: calc(100% + 2px);
        min-width: 70px;
        border-radius: 0;
      }
    }
  }
  &__report {
    display: block;
    width: 22px;
    height: 22px;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 200ms ease;
    @include mq($until: medium) {
      display: none;
    }
    &:hover {
      opacity: 1;
      svg {
        fill: red;
      }
    }
    svg {
      display: block;
      width: 100%;
      height: 100%;
      transition: fill 200ms ease;
    }
  }
  &--locked {
    position: relative;
    border-bottom: 1px solid #e0e2e4;
    &::after {
      content: 'Denne samtale er låst da en af de deltagende brugere er blevet blokeret ⚠';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(30, 60, 56, 0.95);
      font-size: 20px;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
  }
}

.Report {
  .Form {
    margin-bottom: 20px;
  }
  .Button {
    width: 100%;
  }
  h3 {
    margin-bottom: 20px;
  }
}