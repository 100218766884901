.Collaborations {
  max-width: 1200px;
  @include mq($until: medium) {
    margin: 0 -24px;
  }
  &__wrapper {
    h3 {
      @include mq($until: medium) {
        margin: 0 -24px;
      }
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.CollabPreview {
  width: calc(50% - 20px);
  @include mq($until: medium) {
    width: 100%;
  }


  &__inner {
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 1px 2px rgba(0,0,0,.15);
    transition: all .3s ease-in-out;
    background-color: theme-color(white);
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      box-shadow: 0 5px 15px rgba(0,0,0,.3);
      transition: opacity .3s ease-in-out;
      border-radius: 10px;
    }
    &:hover {
      transform: translateY(-4px);
      &::after {
        opacity: 0.8;
      }
      .CollabPreview__footer {
        background-color: theme-color(green);
        p {
          color: theme-color(white);
        }
      }
    }
  }

  &__image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    img {
      border-radius: inherit;
    }
  }

  &__content {
    padding: 20px;
    border-bottom: none;
  }

  &__title {
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;
    line-height: 1.2;
  }

  &__text {
    @include build-font('paragraph-note');
    margin-bottom: 0;
  }

  &__footer {
    padding: 20px;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid theme-color(green);
    transition: background-color .3s ease;
    p {
      margin: 0;
      line-height: 1;
      text-align: center;
      color: theme-color(green);
      transition: color .3s ease;
    }
  }

}

.Collab {

  &__image {
    margin-bottom: 20px;
  }

  &__title {
    @include build-font('headline-3');
    margin-bottom: 20px;
  }

  &__description {
    p {
      @include build-font('paragraph-small');
      white-space: pre-wrap;
      margin-bottom: 10px;
    }
    ul {
      display: block;
      @include build-font('paragraph-small');
      margin: 0 0 10px 0;
    }
    > span {
      @include build-font('paragraph-small');
      display: block;
      font-weight: bold;
    }
  }

  &__footer {
    margin-top: 30px;
    .tooltip {
      @include build-font('paragraph-small');
      color: gray;
      margin-bottom: 10px;
    }
    .Button {
      width: 100%;
    }
    .Button--loading {
      opacity: 0.5;
      cursor: wait;
    }
  }

}

.CollabSkeleton {
  display: inline-block;
  position: relative;
  border-radius: 10px;
  margin-bottom: 40px;
  width: calc(50% - 20px);
  overflow: hidden;
  &::after {
    position: absolute;
    left: -100%;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(200,200,200,0) 0%, #dedede 50%, rgba(200,200,200,0) 100%);
    animation: loadingGradient 1.4s linear infinite;
  }
  div {
    &:nth-child(1) {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      height: 240px;
      background-color: #b9b9b9;
    }
    &:nth-child(2) {
      background-color: lightgray;
      height: 200px;
    }
    &:nth-child(3) {
      background-color: #b9b9b9;
      height: 60px;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
}

@keyframes loadingGradient {
  from { transform: translateX(0%); }
  to { transform: translateX(200%); }
}
