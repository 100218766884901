$theme-colors: (
  orange: #fe9236,
  green: #4ea39a,
  gray: #ebebeb,
  lightgray: #fbfbfb,
  darkgray: #d8d8d8,
  darkishgray: #afafaf,
  darkergray: #323639,
  white: #fff,
  black: #000,
) !default;

$util-colors: (
  warning-text: #856404,
  warning-bg: #fff3cd,
  warning-border: #ffeeba,
  success-text: #155724,
  success-bg: #d4edda,
  success-border: #c3e6cb,
  error-text: #721c24,
  error-bg: #f8d7da,
  error-border: #f5c6cb,
) !default;