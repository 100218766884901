.Page {
  &--home {
    @include mq($until: large) {
      .grid-x {
        .cell {
          &:first-child {
            order: 2;
          }
          &:last-child {
            order: 1;
          }
        }
      }
    }
  }
}