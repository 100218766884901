.Info {
  &__top {
    padding: 30px 20px;
    background-color: transparentize(theme-color(green), 0.6);
    border-radius: 5px;
  }
  &__title, &__subtitle, &__text {
    text-align: center;
  }
  &__title {
    @include build-font(headline-4);
    line-height: 1;
    margin-bottom: 20px;
  }
  &__subtitle {
    @include build-font(paragraph-big);
    @include mq($until: medium) {
      font-size: rem-calc(20px);
    }
    a {
      color: theme-color(black);
      &:hover {
        color: theme-color(orange);
      }
    }
  }
  &__text {
    display: block;
    margin: 0 auto;
    max-width: 550px;
    @include mq($until: medium) {
      font-size: rem-calc(16px);
    }
  }
}