.SignUpSteps {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__footer {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 500px;
    @include mq($until: large) {
      max-width: none;
    }
    @include mq($from: xlarge) {
      max-width: 550px;
    }
    @include mq($from: xxlarge) {
      max-width: 650px;
    }
    button {
      display: block;
      width: 200px;
      margin-left: auto;
    }
    .Form__errorMessage {
      li {
        @include build-font('paragraph-small');
        margin-bottom: 6px;
        a {
          font-weight: bold;
          display: block;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.SignUpStep {
  margin-top: 16vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;  
  max-width: 500px;
  @include mq($until: large) {
    max-width: none;
    margin-top: 0;
  }
  @include mq($from: xlarge) {
    max-width: 550px;
  }
  @include mq($from: xxlarge) {
    max-width: 650px;
  }
  &__title {
    display: block;
    @include build-font('headline-3');
    font-weight: 400;
    max-width: 430px;
    line-height: 1.4;
    @include mq($until: large) {
      max-width: none;
    }
  }
  &__subtitle {
    @include build-font('headline-4');
    font-weight: 400;
    max-width: none;
    margin-top: 80px;
    padding-top: 65px;
    display: block;
    border-top: 1px solid #cdcee4;
    margin-bottom: 20px;
    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
  }
  &__subsubtitle {
    @include build-font('paragraph-big');
    margin-bottom: 10px;
    font-weight: 400;
    margin-top: 40px;
  }
  &__resendBtn {
    margin-top: 5px;
  }
  &__emailinfo {
    @include build-font('paragraph-small');
    margin: 0;
    @include mq($until: medium) {
      @include build-font('paragraph-note');
    }
  }
  &__mm {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    @include mq($until: large) {
      margin-bottom: 50px;
    }
    @include mq($until: medium) {
      margin-bottom: 30px;
    }
  }
  &__mentor, &__mentee {
    display: block;
    outline: 0;
    width: calc(50% - 20px);
    padding: 40px 20px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid transparentize(theme-color(green), 0.4);
    transition: all .175s ease-in-out;
    will-change: transform;
    @include mq($until: medium) {
      width: calc(50% - 4px);
    }
    &:hover {
      background-color: transparentize(theme-color(green), 0.8);
    }
    svg {
      display: block;
      margin: 0 auto 20px auto;
      max-width: 85px;
      fill: theme-color(green);
      transition: fill .175s ease-in-out;
    }
    h3 {
      text-align: center;
      margin-bottom: 0;
      color: theme-color(green);
      line-height: 1;
      transition: color .175s ease-in-out;
    }
    &--selected {
      background-color: theme-color(orange);
      border-color: transparentize(theme-color(orange), 0.4);
      box-shadow: 0 0 20px 1px rgba(0,0,0,.2);
      transform: translateY(-5px);
      > h3 {
        color: theme-color(white);
      }
      > svg {
        fill: theme-color(white);
      }
      &:hover {
        background-color: theme-color(orange);
      }
    }
  }
  &__avatar {
    display: block;
    opacity: 1 !important;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 0 22px 0 !important;
    cursor: pointer;
    background-color: transparentize(theme-color(green), 0.5);
    &.missing-input {
      border: 1px solid red;
    }
    &--imageUploaded {
      p {
        opacity: 0 !important;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
    svg {
      padding-top: 22px;
      display: block;
      width: 100%;
      height: 100%;
      fill: #fcfcff;
    }
    p {
      position: absolute;
      left: 0;
      bottom: 0;
      @include build-font(paragraph-note);
      display: block;
      width: 100%;
      margin: 0;
      padding: 10px 0 14px 0;
      text-align: center;
      background-color: transparentize(theme-color(black), 0.45);
      color: theme-color(white);
      opacity: 1;
      transition: opacity 200ms ease;
    }
    input {
      top: 0;
      position: fixed!important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px,1px,1px,1px);
    }
  }
  input[type=text] + .Form__errorMessage--showMessage {
    margin-bottom: 20px;
  }
  .Form {
    @include mq($until: medium) {
      margin-bottom: 30px;
    }
  }
  &__passwordCriterias {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      span {
        @include build-font('paragraph-small');
        line-height: 1;
        margin: 0;
      }
      svg {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      &.valid {
        color: green;
        svg {
          fill: green;
        }
      }
      &.not-valid {
        // color: red;
        svg {
          fill: red;
        }
      }
    }
  }
}

