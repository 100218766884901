.EmailValidation {
  display: block;
  min-height: 100vh;
  width: 100%;
  background-color: #f7f7fc;
  padding: 20px;
  @include mq($from: large) {
    padding: 50px 20px;
  }
  .Logo {
    margin-bottom: 40px;
  }
  &__inner {
    display: block;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #fff;
    max-width: 600px;
    @include mq($from: large) {
      padding: 40px 20px;
    }
    h1 {
      @include build-font('headline-4');
      text-align: center;
      margin-bottom: 20px;
    }
    h2 {
      @include build-font('paragraph-big');
      text-align: center;
      margin-bottom: 30px;
      font-weight: 400;
    }
    a {
      display: block;
      margin-bottom: 30px;
      text-align: center;
      color: theme-color(green);
      text-decoration: underline;
    }
    svg {
      display: block;
      width: 70px;
      height: auto;
      margin: 0 auto;
    }
  }
}