// Standard button
.Button {
	display: inline-block;
	padding: 14px 16px;
	min-width: 150px;

	// Text
  text-align: center;
	@include build-font('paragraph-small');
	line-height: 1;

	// Standard btn colors
	background-color: theme-color(orange);
	color: theme-color(white);

	// Border
	border: 2px solid theme-color(orange);
	border-radius: 4px;

	// Reset
	outline: 0;
	text-decoration: none;
	cursor: pointer;

	// Transition
	transition: all .2s ease;

	&:hover {
    background-color: darken(theme-color(orange), 5%);
    border-color: darken(theme-color(orange), 5%);
		transform: translateY(-1px);
	}

	&:active {
		transform: translateY(1px);
	}

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	svg {
		fill: theme-color(white);
	}

}

// Big btn
.Button--big {
	@include build-font('paragraph');
	padding: 16px 24px;
}

// Standard btn outline
.Button--outline {
	background-color: transparent;
	color: theme-color(orange);
	border-color: theme-color(orange);
	svg {
		fill: theme-color(orange);
	}
	&:hover {
		background-color: theme-color(orange);
		color: theme-color(white);
		svg {
			fill: theme-color(white);
		}
	}
}

// Button with green
.Button--green {
	background-color: theme-color(green);
	color: theme-color(white);
	border-color: theme-color(green);
	&:hover {
    background-color: darken(theme-color(green), 5%);
    border-color: darken(theme-color(green), 5%);
	}
}

.Button--green--outline {
	background-color: transparent;
	color: theme-color(green);
	border-color: theme-color(green);
	svg {
		fill: theme-color(green);
	}
	&:hover {
		background-color: theme-color(green);
		color: theme-color(white);
		border-color: theme-color(green);
		svg {
			fill: theme-color(white);
		}
	}
}

.Button--red {
	background-color: transparent;
	color: #ff2a3d;
	border-color: #ff2a3d;
	svg {
		fill: theme-color(white);
	}
	&:hover {
		background-color: #ff2a3d;
		color: theme-color(white);
		border-color: #ff2a3d;
		svg {
			fill: theme-color(white);
		}
	}
}

.Button--gray {
	background-color: theme-color(darkishgray);
	border-color: theme-color(darkishgray);
	color: theme-color(white);
	&:hover {
		background-color: darken(theme-color(darkishgray), 10%);
		border-color: darken(theme-color(darkishgray), 10%);
		color: theme-color(white);
	}
}

.Button--gray--outline {
	background-color: transparent;
	border-color: theme-color(darkishgray);
	color: darken(theme-color(darkishgray), 10%);
	&:hover {
		background-color: darken(theme-color(darkishgray), 10%);
		border-color: darken(theme-color(darkishgray), 10%);
		color: theme-color(white);
	}
}

// Button with icon
.Button--icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
  span {
		display: block;
    margin-right: 12px;
  }
  svg {
		display: block;
    width: 20px;
		height: 20px;
		transition: fill .2s ease;
	}
}