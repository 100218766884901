.Loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  svg {
    display: block;
    width: 100%;
    max-width: 200px;
    height: auto;
  }
}