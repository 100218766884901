//====================================
// Includes
//====================================
@import 'headlines';
@import 'paragraphs';
@import 'lists';
@import 'links';
@import 'buttons';
@import 'labels';
@import 'inputs';

@mixin typography-reset {
  font-family: $theme-font-family;
  font-size: 100%;
  line-height: 1.3;
  font-weight: 300;
  color: theme-color(black);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}


//
// Default Styles
//
@mixin typography-everything {
  html {
    @include typography-reset;
  }

  @include typography-links;
  @include typography-headlines;
  @include typography-paragraphs;
  @include typography-lists;
}


::selection {
  background-color: theme-color(orange);
  color: theme-color(green);
}

body {
  b, strong {
    font-weight: bold;
  }
}