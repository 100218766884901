.Form {
  label {
    display: block;
    margin-bottom: 5px;
    opacity: 0.68;
    @include build-font(paragraph-small);
  
    a {
      text-decoration: underline;
    }
  }
  .tooltip {
    display: block;
    @include build-font(paragraph-note);
    opacity: 0.5;
    margin-bottom: 20px;
    a {
      color: theme-color(orange);
      text-decoration: underline;
    }
    &.black {
      opacity: 1;
    }
  }
  input + .tooltip, textarea + .tooltip, .Form__errorMessage + .tooltip {
    margin-top: -14px;
  }
}
