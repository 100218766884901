.Accordion {
  position: relative;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px 15px;
    background-color: #f2f2f2;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 200ms ease;
    &:hover {
      background-color: #e6e6e6;
    }
  }
  &__icon {
    position: relative;
    width: 30px;
    height: 30px;
    min-width: 30px;
    div {
      position: absolute;
      width: 2px;
      height: 16px;
      background: darkgray;
      transition: .2s transform ease,.2s -webkit-transform ease;
      left: 14px;
      top: 7px;
      &:first-child {
        transform: rotate(-90deg);
      }
    }
  }
  &__checkbox {
    opacity: 0;
    position: absolute;
    z-index: 1;
    &:checked {
      ~ label {
        border-radius: 4px 4px 0 0;
        .Accordion__icon div {
          transform: rotate(90deg);
        }
      }
      ~ .Accordion__content  {
        height: auto;
        visibility: visible;
        opacity: 1;
        border: 1px solid #f2f2f2;
        border-top: 0;
        padding: 15px 20px;
      }
    }
  }
  &__content {
    margin: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    border-radius: 0 0 4px 4px;
    transition: visibility 0s, opacity 0.3s linear;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.Faqs {
  padding: 10px 0 5px 0;
}