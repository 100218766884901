.ConversationPreview {

  &--unread {
    .ConversationPreview__message {
      opacity: 1;
      font-weight: bold;
    }
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: -24px;
    margin-right: -24px;
    border-bottom: 1px solid #e0e2e4;
    transition: background-color 200ms ease;
    &:hover {
      background-color: transparentize(theme-color(orange), 0.8);
    }
  }
  &:first-child a {
    margin-top: -10px;
  }
  &:last-child a {
    border-bottom: none;
    margin-bottom: -20px;
  }

  &__image {
    display: block;
    position: relative;
    width: 50px;
    min-width: 50px;
    height: 50px;
    overflow: hidden;    
    border-radius: 50%;
    margin-right: 25px;
    background-color: lightgray;
    @include mq($until: medium) {
      margin-right: 15px;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
  }

  &__name, &__message, &__date {
    margin: 0;
    line-height: 1;
  }

  &__inner {
    position: relative;
  }

  &__name {
    @include build-font('paragraph-small');
    margin-bottom: 5px;
  }

  &__unread {
    position: absolute;
    right: 150px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: theme-color(orange);
  }

  &__message {
    @include build-font('paragraph-note');
    opacity: 0.4;
    max-width: 500px;
    line-height: 1.1;
  }

  &__date {
    margin-left: auto;
    @include build-font('paragraph-note');
    opacity: 0.5;
    @include mq($until: medium) {
      display: none;
    }
  }

  &--locked {
    a {
      background-color: #e0dfdf;
      &:hover {
        background-color: darken(#e0dfdf, 10%);
        .ConversationPreview__locked {
          color: red;
        }
      }
    }
  }

  &__locked {
    @include build-font('paragraph-note');
    margin: 0;
    color: darkred;
    margin-left: auto;
    transition: color 200ms ease;
  }

}