.ConversationShortcuts ul {
  display: flex;
  @include mq($until: medium) {
    display: none;
  }
}

.ConversationShortcut {
  display: block;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  a {
    display: block;
    width: 32px;
    height: 32px;
  }
  figure {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background-color: lightgray;
  }
  img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    font-family: "object-fit: cover;";
  }
  .Tooltip {
    height: 100%;
    width: 100%;
  }
  &--locked {
    display: none;
  }
}