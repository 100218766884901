//
// Loop through properties in config
//
@mixin build-properties($properties) {
  @each $property, $value in $properties {
    // Add properties
    #{$property}: $value;
  }
}

//
// Build properties for breakpoints
//
@mixin build-config($config) {
  // Each fontsize
  @each $breakpoint, $properties in $config {
    // If deafult
    @if $breakpoint == default {
      // Build deafult config
      @include build-properties($properties);
    } @else {
      // Check if breakpoint exists
      // $breakpoints is defined in varaibles
      @if map-has-key($breakpoints, $breakpoint) {
        // Add media query wrapper
        @include mq($from: $breakpoint) {
          // Build default config
          @include build-properties($properties);
        }
      }
    }
  }
}

//
// Build properties for breakpoints
//
@mixin build-font($name) {
  @include build-config(get-config($theme-fonts, $name));
}
