.Stats {
  max-width: 1400px;
  padding-bottom: 40px;
  svg {
    display: block;
    max-width: 400px;
    margin: 0 auto;
  }
}

.UsersTable, .Stats {
  .Button--loading {
    opacity: 0.5;
    cursor: wait;
  }
}

.StatItem {
  border-radius: 20px;
  padding: 20px;
  background-color: lighten(theme-color(green), 42%);
  text-align: center;
  margin-bottom: 20px;

  &__title {
    margin-bottom: 16px;
    line-height: 1;
  }

  &__value {
    @include build-font('headline-2');
    line-height: 1;
    font-weight: normal;
  }
}

.StatItem--expandable {
  position: relative;
  max-height: 350px;
  overflow: hidden;
  > div:last-child {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(78,163,154,1) 0%, rgba(78,163,154,0) 90%);
    button {
      display: block;
      padding: 12px 20px;
      background: theme-color(orange);
      color: theme-color(white);
      border-radius: 20px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}

.StatItem--expandable--expanded {
  max-height: none;
  > div:last-child {
    display: none;
  }
}

.StatsTable {
  margin: 0 auto;
  text-align: left;
}

.UsersTable {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  img {
    width: 100%;
    height: auto;
  }
  th:nth-child(1) {
    width: 134px;
  }
  td:not(:first-child) {
    padding: 0 10px;
  }
  th {
    background-color: theme-color(green);
    border: 1px solid darken(theme-color(green), 25%);
    padding: 10px;
    position: sticky;
    top: 58px;
  }
  td {
    border: 1px solid darken(theme-color(green), 25%);
  }
  a {
    text-decoration: underline;
    color: theme-color(green);
  }
  .Button {
    min-width: 0;
  }
}

.PieSkeleton {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  background-color: darkgray;
  border-radius: 50%;
  opacity: 0.5;
}