.Notifications {
  position: relative;

  &--unread {
    &::after, &::before {
      content: '';
      position: absolute;
      top: 8px;
      right: 8px;
      width: 8px;
      height: 8px;
      background-color: theme-color(orange);
      border-radius: 50%;
      pointer-events: none;
    }
    &::before {
      opacity: 1;
      transform: scale(0);
      animation-fill-mode: forwards;
      animation: jumper 1s 1.5s ease-out 4;
    }
    @keyframes jumper {
      0% {
        transform: scale(0);
        width: 10px;
        height: 10px;
        opacity: 1;
      }
      100% {
        transform: scale(4);
        opacity: 0;
      }
    }
  }

  &--open {
    .Notifications__toggle {
      background-color: transparentize(theme-color(green), 0.8);
      svg {
        fill: theme-color(green);
      }
    }
  }

  &__toggle {
    display: block;
    padding: 10px;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 200ms ease;
    &:hover {
      background-color: transparentize(theme-color(green), 0.8);
      svg {
        fill: theme-color(green);
      }
    }
    svg {
      display: block;
      width: 21px;
      height: 21px;
      fill: theme-color(black);
      transition: fill 200ms ease;
      opacity: 0.9;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e2e4;
    background-color: lighten(theme-color(green), 52%);
    span {
      @include build-font('paragraph-note');
      margin: 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__title {
    @include build-font('paragraph-note');
    margin-bottom: 0;    
  }

  &__inner {
    position: absolute;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    top: 50px;
    width: 430px;
    right: 0;
    border-radius: 2px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
    background-color: theme-color(white);
    transition: visibility 0s, opacity 0.15s linear;
    @include mq($until: medium) {
      position: fixed;
      width: 100vw;
    }
    &--open {
      height: auto;
      visibility: visible;
      opacity: 1;
      overflow: visible;
    }
    &::after {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
      top: 0px;
      right: 15px;
      transform: rotate(180deg) translateY(10px);
      @include mq($until: medium) {
        right: 31px;
      }
    }
  }

  &__scroll {
    overflow: hidden;
    overscroll-behavior: contain;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
    @include mq($until: medium) {
      max-height: calc(100vh - 80px);
    }
  }

  &__notfound {
    padding: 10px 20px;
    margin: 0;
  }

}

.Notification {
  position: relative;
  display: block;
  border-bottom: 1px solid #e0e2e4;
  
  &--unread {
    .Notification__text {
      font-weight: bold;
    }
    &::after {
      content: '';
      position: absolute;
      top: 30px;
      right: 110px;
      width: 8px;
      height: 8px;
      background-color: theme-color(orange);
      border-radius: 50%;
      pointer-events: none;
    }
  }

  &__image {
    position: relative;
    width: 48px;
    height: 48px;
    overflow: hidden;
    margin-right: 12px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
  }

  a {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 200ms ease;
    &:hover {
      background-color: transparentize(theme-color(orange), 0.8);
    }
  }

  .LogoIcon {
    height: 100%;
    img {
      position: relative;
      object-fit: initial;
      border-radius: 0;
    }
  }

  &__text {
    @include build-font('paragraph-note');
    margin-bottom: 0;
    width: 200px;
    line-height: 1.3;
    margin-right: 10px;
  }

  &__time {
    @include build-font('paragraph-note');
    margin-bottom: 0;
    margin-left: auto;
    opacity: 0.5;
  }

}