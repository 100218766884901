.SignUpFlow {
  height: 100vh;
  @include mq($until: large) {
    height: auto;
  }

  $padding-vertical: 70px;
  $padding-horizontal: 40px;

  &__instructions {
    position: fixed;
    top: 0;
    left: 0;
    padding: $padding-vertical $padding-horizontal;
    width: 38%;
    height: 100%;
    background-color: #f7f7fc;
    @include mq($from: large) {
      @media only screen and (max-height: 600px) {
        padding: 40px;
      }
    }
    @include mq($until: large) {
      position: relative;
      width: 100%;
      height: auto;
      padding: 40px;
    }
    @include mq($until: medium) {
      padding: 20px;
    }
    @include mq($from: xlarge) {
      width: 600px;
    }
  }
  &__content {
    .SignUpSteps {
      padding: $padding-vertical $padding-horizontal;
      overflow-y: scroll;
      @include mq($until: large) {
        padding: 40px;
      }
      @include mq($until: medium) {
        padding: 30px 20px;
      }
    }
    width: 100%;
    height: 100%;
    padding-left: 38%;
    background-color: theme-color(white);
    @include mq($until: large) {
      padding-left: 0;
      height: auto;
    }
    @include mq($from: xlarge) {
      padding-left: 600px;
    }
  }
}