$theme-fonts: (
  'headline-1': (
    default: (
      'font-size': rem-calc(45px),
      'line-height': 1.2,
      'font-weight': bold,
      'margin-bottom': 32px,
    ),
    large: (
      'font-size': rem-calc(52px),
    )
  ),
  'headline-2': (
    default: (
      'font-size': rem-calc(40px),
      'line-height': 1.2,
      'font-weight': bold,
    ),
    large: (
      'font-size': rem-calc(42px),
    )
  ),
  'headline-3': (
    default: (
      'font-size': rem-calc(34px),
      'line-height': 1.2,
      'font-weight': bold,
    )
  ),
  'headline-4': (
    default: (
      'font-size': rem-calc(22px),
      'line-height': 1.2,
      'font-weight': bold,
    ),
    medium: (
      'font-size': rem-calc(28px),
    )
  ),
  'paragraph': (
    default: (
      'font-size': rem-calc(18px),
      'line-height': 1.75,
    ),
  ),
  'paragraph-small': (
    default: (
      'font-size': rem-calc(16px),
    ),
  ),
  'paragraph-note': (
    default: (
      'font-size': rem-calc(14px),
    ),
  ),
  'paragraph-tiny': (
    default: (
      'font-size': rem-calc(12px),
    ),
  ),
  'paragraph-big': (
    default: (
      'font-size': rem-calc(24px),
      'line-height': 1.4,
    ),
  ),
) !default;
