.NewsArticle {

  a {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: -24px;
    margin-right: -24px;
    border-bottom: 1px solid #e0e2e4;
    cursor: pointer;
    transition: background-color 200ms ease;
    &:hover {
      background-color: transparentize(theme-color(orange), 0.8);
    }
  }
  &:first-child {
    a {
      margin-top: -10px;
    }

  }
  &:last-child {
    a {
      border-bottom: none;
      margin-bottom: -20px;
    }
  }

  &__image {
    width: 80px;
    margin-right: 20px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &__title {
    @include build-font('paragraph-small');
    margin: 0 0 5px 0;
    line-height: 1; 
  }
  &__dateauthor {
    @include build-font('paragraph-note');
    line-height: 1;
    margin: 0 0 0 auto;
    color: gray;
  }
}