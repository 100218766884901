.Popup {

  &__title {
    display: block;
    margin: 0 auto 20px auto;
    text-align: center;
    @include build-font('paragraph-big');
    max-width: 360px;
  }

  &__illustration {
    margin-bottom: 40px;
    svg {
      display: block;
      max-width: 300px;
      margin: 0 auto;
    }
    #HighFive {
      padding: 10px;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
  }

}