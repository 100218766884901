.Match {
  margin: 0 -20px;
  border-bottom: 1px solid #e0e2e4;
  transition: background-color 200ms ease;
  @include mq($from: medium) {
    min-width: 600px;
  }
  &:hover {
    background-color: transparentize(theme-color(orange), 0.8);
    .Match__expand {
      fill: theme-color(green);
    }
  }
  &:first-child {
    margin-top: -20px;
  }
  &--open {
    background-color: transparentize(theme-color(green), 0.8);
    &:hover {
      background-color: transparentize(theme-color(green), 0.8);
      .Match__expand {
        fill: theme-color(black);
      }
    }
    .Match__inner {
      height: auto;
      visibility: visible;
      opacity: 1;
    }
    .Match__expand {
      transform: rotate(180deg);
    }
  }
  &__top {
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    outline: 0;
    cursor: pointer;
    @include mq($until: medium) {
      padding-left: 16px;
      padding-right: 16px;
      > div {
        margin-right: 14px;
        &:nth-child(2) {
          max-width: 120px;
          h3 {
            word-break: break-word;
            line-height: 1.2;
          }
          p {
            word-break: break-all;
          }
        }
      }
    }
  }
  &__image {
    display: block;
    position: relative;
    width: 80px;
    min-width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
    @include mq($until: medium) {
      width: 62px;
      min-width: 62px;
      height: 62px;
      margin-right: 16px;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
  }
  &__name {
    @include build-font('paragraph');
    font-weight: bold;
    line-height: 1;
    margin: 0 0 8px 0;
    @include mq($until: medium) {
      @include build-font('paragraph-small');
    }
  }
  &__title, &__company {
    @include build-font('paragraph-small');
    margin: 0;
    line-height: 1;
    @include mq($until: medium) {
      @include build-font('paragraph-note');
    }
  }
  &__title {
    margin-bottom: 5px;
  }
  &__score {
    @include build-font('paragraph-tiny');
    padding: 0 20px;
    margin: 0;
    margin-left: auto;
    width: auto;
    min-width: 120px;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    background-color: theme-color(green);
    color: theme-color(white);
    @include mq($until: medium) {
      padding: 0 10px;
      min-width: 100px;
    }
    &--top {
      background-color: theme-color(orange);
    }
    &--good {
      background-color: theme-color(green);
    }
    &--match {
      background-color: lighten(theme-color(green), 10%);
    }
  }
  &__expand {
    display: block;
    width: 18px;
    min-width: 18px;
    height: 18px;
    fill: theme-color(green);
    margin-left: 20px;
    transition: fill 200ms ease, opacity 200ms ease;
    @include mq($until: medium) {
      margin-left: 10px;
    }
  }
  &__inner {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    > div {
      padding-left: 24px;
      padding-right: 24px;
      @include mq($until: medium) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
  &__info {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    border-top: 1px solid rgba(77, 163, 154, 0.15);
    p {
      @include build-font('paragraph-small');
      margin: 0;
    }
  }
  &__linkedinbtn {
    padding: 8px 10px;
    svg {
      width: 16px;
      height: 16px;
      transform: translateY(-0.5px);
    }
    &--none {
      background-color: theme-color(darkgray);
      border-color: theme-color(darkgray);
      color: theme-color(white);
      svg {
        fill: theme-color(white);
      }
      pointer-events: none;
      &:hover {
        background-color: theme-color(darkgray);
        border-color: theme-color(darkgray);
        transform: none;
        cursor: not-allowed;
      }
    }
  }
  &__info__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__moreinfo {
    display: flex;
    margin-left: auto;
    .Tooltip {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
  &__criterias__wrapper {
    display: flex;
    margin-bottom: 20px;
  }
  &__criterias {
    @include mq($until: medium) {
      margin-bottom: 10px;
    }
    &:first-child {
      margin-right: 20px;
      max-width: 150px;
      min-width: 150px;
    }
    &:last-child {
      margin-right: 0;
      max-width: none;
      min-width: 0;
    }
    > span {
      display: block;
      font-weight: bold;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      @include build-font('paragraph-note');
      padding: 6px 8px;
      margin: 0 4px 4px 0;
      color: theme-color(white);
      line-height: 1;
      background-color: theme-color(green);
      border-radius: 4px;
    }
    span {
      @include build-font('paragraph-small');
      @include mq($until: medium) {
        @include build-font('paragraph-note');
      }
    }
  }
  &__indicator {
    display: block;
    width: 100%;
    width: 24px;
    height: 24px;
    fill: theme-color(green);
  }
  .Tooltip {}
  &__info__headline {
    @include build-font('paragraph-small');
    margin: 0;
    font-weight: bold;
  }
  &__request {
    padding-bottom: 14px;
    .Form {
      margin: 0;
    }
    textarea {
      resize: none;
      display: block;
      width: 100%;
      @include build-font('paragraph-small');
      margin-bottom: 10px;
    }
    .Button {
      display: block;
      width: 100%;
    }
    .Button--loading {
      cursor: wait;
    }
    .Button--gray {
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      &.Button--loading {
        background-color: darken(theme-color(darkishgray), 10%);
        border-color: darken(theme-color(darkishgray), 10%);
        color: theme-color(white);
      }
    }
    .Form__successMessage {
      border-color: #79af86;
    }
  }
  &__unavailable {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: theme-color(darkgray);
    border: 1px solid darken(theme-color(green), 10%);
    border-right: none;
    border-left: none;
    p {
      @include build-font('paragraph-small');
      margin-bottom: 0;
    }
  }
}

.Matches__pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: -20px;
  padding: 20px 0;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    padding: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      fill: theme-color(green);
      transition: fill 200ms ease;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        svg {fill: theme-color(green)}
      }
    }
    &:hover {
      svg {
        fill: theme-color(orange);
      }
    }
    &:first-child {
      svg {
        transform: rotate(90deg);
      }
    }
    &:last-child {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
  ul {
    display: flex;
  }
  li {
    button {
      display: block;
      border: none;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0 5px 0 0;
      text-align: center;
      background: none;
      color: theme-color(green);
      cursor: pointer;
      transition: color 200ms ease;
      &:hover {
        color: theme-color(orange);
      }
    }
    &.current {
      button {
        color: theme-color(orange);
      }
    }
    &:last-child {
      button {
        margin-right: 0;
      }
    }
  }
}