.Topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 60px;
  background-color: theme-color(white);
  border-bottom: 1px solid #e0e2e4;
  padding-left: 40px;
  margin-left: 72px; /* width of sidebar */
  padding-right: 20px;
  z-index: 9;
  @include mq($until: medium) {
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    height: 50px;
    margin-left: 0;
    z-index: 8;
  }

  > div:nth-child(2) {
    margin-right: auto;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    .ConversationShortcut {
      margin-left: 40px;
      margin-right: 14px;
      @include mq($until: medium) {
        margin-left: 0;
      }
    }
  }

  &__notifications {
    margin-left: 20px;
  }

  &__burger {
    display: none;
    width: 34px;
    height: 34px;
    padding: 5px;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
    @include mq($until: medium) {
      display: block;
    }
  }

  &__scrim {
    display: none;
    background: rgba(0,0,0,.4);
    cursor: pointer;
    z-index: 8;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease,visibility 0s ease .3s;

    @include mq($until: medium) {
      display: block;
    }
  }

  &.Sidebar--open {
    .Topbar__scrim {
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
      transition: opacity .3s ease;
    }
  }

}