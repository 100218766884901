.SignUpInstructions {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  &__steps {
    display: flex;
    @include mq($until: large) {
      justify-content: center;
      margin-bottom: 20px;
    }

    p {
      @include build-font('paragraph-note');
      margin-bottom: 0;
      opacity: 0.8;
      display: block;
      line-height: 1;
      @include mq($until: large) {
        height: auto;
      }
      span:first-child {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        height: 30px;
        width: 30px;
        border: 1px solid theme-color(green);
        color: theme-color(green);
        @include build-font('paragraph-small');
        border-radius: 50%;
        margin-right: 10px;
        svg {
          display: block;
          width: 13px;
          height: 13px;
          fill: theme-color(white);
        }
      }
      span:last-child {
        display: none;
      }
      &.current {
        opacity: 1;
        span {
          border-color: theme-color(orange);
          color: theme-color(orange);
        }
        span:last-child {
          display: inline-block;
          margin-right: 20px;
          @include mq($until: medium) {
            display: none;
          }
        }
      }
      &.done {
        opacity: 1;
        span {
          background-color: theme-color(green);
        }
      }
    }
  }

  &__illustration {
    svg {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;
      padding: 40px;
      max-width: 450px;
      overflow: visible;
      @include mq($from: large) {
        @media only screen and (max-height: 730px) {
          max-width: 300px;
          padding: 20px;
        }
      }
      @include mq($until: large) {
        padding: 0;
        max-width: 200px;
        margin-bottom: 20px;
      }
      @include mq($until: medium) {
        max-width: 120px;
      }
    }
    #Celebration {
      padding: 0;
      margin-bottom: 40px;
    }
  }

  &__title {
    @include build-font('headline-4');
    text-align: center;
    margin: 0;
    font-weight: 300;
    @include mq($until: medium) {
      @include build-font('paragraph');
      line-height: 1;
    }
  }

  &__why {
    display: flex;
    align-items: center;
    max-width: 380px;
    @include mq($until: large) {
      display: none;
    }
    svg {
      fill: #cdcee4;
      display: block;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
      margin-right: 20px;
    }
    p {
      @include build-font('paragraph-note');
      margin: 0;
      opacity: 0.5;
    }
  }

}