.color-orange {
  color: theme-color(orange);
}

.color-green {
  color: theme-color(green);
}

.color-white {
  color: theme-color(white);
}

.color-black {
  color: theme-color(black);
}

.color-gray {
  color: theme-color(darkgray);
}

.bg-white {
  background-color: theme-color(white);
}

.bg-black {
  background-color: theme-color(black);
}

.bg-lightgray {
  background-color: theme-color(lightgray);
}

.bg-orange {
  background-color: theme-color(orange);
}

.bg-green {
  background-color: theme-color(green);
}

.bg-lightgreen {
  background-color: #4fa79a;
  color: #fff;
  border: 1px solid #49a597;
}

.border-gray {
  border: 1px solid #e0e2e4;
}

.overflow-hidden {
  overflow: hidden;
}


