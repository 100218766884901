//====================================
// RESET
//====================================
@import '../../node_modules/normalize-scss/sass/normalize';

@mixin reset-box-sizing {
  html {
    box-sizing: border-box;
  }

  // Set box-sizing globally to handle padding and border widths
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

@mixin reset-highlight-color {
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

@mixin reset-media-element {
  figure {
    margin: 0;
  }

  img,
  video {
    // Get rid of gap under images by making them display: inline-block; by default
    display: inline-block;
    vertical-align: middle;

    // Grid defaults to get images and embeds to work properly
    width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
  }
}

@mixin reset-button {
  button {
    background: none;
    border: none;
    outline: none;
  }
}

@mixin reset-all {
  @include normalize();
  @include reset-box-sizing;
  @include reset-highlight-color;
  @include reset-media-element;
  @include reset-button;
}