.Sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 72px;
  display: flex;
  flex-direction: column;
  background-color: theme-color(white);
  border-right: 1px solid #e0e2e4;
  z-index: 8;

  @include mq($until: medium) {
    z-index: 9;
    visibility: hidden;
    box-shadow: 0 4px 4px 0 rgba(60,64,67,.30), 0 8px 12px 6px rgba(60,64,67,.15);
    transform: translate3d(-72px,0,0);
    transition: transform .3s cubic-bezier(0.4,0.0,1,1),visibility 0s ease .3s;
    &--open {
      transform: translate3d(0,0,0);
      visibility: visible;
      transition: transform .3s cubic-bezier(0.0,0.0,0.2,1);
    }
  }

  .LogoIcon {
    padding: 16px;
    height: 60px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e0e2e4;
    @include mq($until: medium) {
      height: 50px;
      padding: 10px;
      margin-bottom: 0;
      img {
        display: block;
        margin: 0 auto;
        width: 32px;
        height: 32px;
      }
    }
  }

  &__nav, &__bottom {
    a.current-page {
      svg {
        fill: theme-color(green);
      }
      span {
        color: theme-color(green);
      }
    }
    a, button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      height: auto;
      padding: 12px;
      transition: background-color 200ms ease;
      svg {
        transition: fill 150ms ease;
      }
      span {
        transition: color 150ms ease;
      }
      &:hover {
        background-color: transparentize(theme-color(green), 0.8);
      }
    }
    svg {
      display: block;
      width: 25px;
      height: 25px;
      fill: #1a202c;
    }
    span {
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.7rem;
      margin: 0;
      color: #1a202c;
    }
    .UnreadConversation {
      position: relative;
      &::after, &::before {
        content: '';
        position: absolute;
        top: 10px;
        right: 18px;
        width: 10px;
        height: 10px;
        background-color: theme-color(orange);
        border-radius: 50%;
        pointer-events: none;
      }
      &::before {
        opacity: 1;
        transform: scale(0);
        animation-fill-mode: forwards;
        animation: jumper 1s 1.5s ease-out infinite;
      }
    }
  }

  &__bottom {
    margin-top: auto;
    svg {
      margin-bottom: 2px;
    }
    button {
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
    }
    a:first-child:hover {
      background-color: transparentize(#cce5ff, 0.6);
      span {
        color: #004085;
      }
      svg {
        fill: #004085;
      }
    }
    button:last-child:hover {
      background-color: transparentize(util-color(error-bg), 0.1);
      span {
        color: util-color(error-text);
      }
      svg {
        fill: util-color(error-text);
      }
    }
  }

  @keyframes jumper {
    0% {
      transform: scale(0);
      width: 10px;
      height: 10px;
      opacity: 1;
    }
    100% {
      transform: scale(4);
      opacity: 0;
    }
  }

}
