.Matchmaker {
  display: block;
  @media only screen and (max-width: 340px) {
    padding: 30px 5px;
  }
  p {
    @include build-font('paragraph-note');
    text-align: center;
    margin: 14px 0 0 0;
    a {
     color: theme-color(green); 
    }
  }
  &__start {
    display: flex;
    justify-content: center;
  }
  .Button {
    text-transform: uppercase;
    &--searching {
      opacity: 0.7;
      pointer-events: none;
    }
  }
}

.MatchAnimation {
  max-width: 300px;
  margin: 0 auto 20px auto;
  @media only screen and (max-width: 340px) {
    margin-bottom: 46px;
  }
  filter: grayscale(100%);

  &--searching {
    filter: none;
    .MatchAnimation__pulse {
      animation-name: pulse;
    }
    .MatchAnimation__circles {
      div {
        &:nth-child(1) { animation-name: jumpOne; }
        &:nth-child(2) { animation-name: jumpTwo; }
        &:nth-child(3) { animation-name: jumpThree; }
        &:nth-child(4) { animation-name: jumpFour; }
      }
    }
    .MatchAnimation__inner {
      > img {
        animation: popup 5.5s ease infinite;
      }
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    .LogoIcon {
      position: relative;
      margin: 0 auto;
      width: 40px;
      height: 40px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  &__pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0) translate(-50%, -50%);
    width: 300px;
    height: 300px;
    background-color: theme-color(orange);
    border-radius: 50%;
    opacity: 0.5;

    // animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    transform-origin: top left;
  }
  &__circles {
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform-origin: top left;
      border: 2px solid theme-color(green);
      border-radius: 50%;

      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
      animation-duration: 1s;

      &:nth-child(1) {
        width: 70px;
        height: 70px;
        opacity: 0.6;
        // animation-name: jumpOne;
        animation-delay: 130ms;
      }
      &:nth-child(2) {
        width: 140px;
        height: 140px;
        opacity: 0.5;
        // animation-name: jumpTwo;
        animation-delay: 250ms;
      }
      &:nth-child(3) {
        width: 210px;
        height: 210px;
        opacity: 0.3;
        // animation-name: jumpThree;
        animation-delay: 450ms;
      }
      &:nth-child(4) {
        width: 280px;
        height: 280px;
        opacity: 0.2;
        // animation-name: jumpFour;
        animation-delay: 700ms;
      }
    }
  }
  &__inner {
    > img {
      position: absolute;
      width: 36px;
      height: 36px;
      border-radius: 5px;
      z-index: 2;
      // animation: popup 5.5s ease infinite;
      opacity: 0;
      transform: scale(0);
      &:nth-child(1) {
        top: 30px;
        left: 20px;
        animation-delay: 0;
      }
      &:nth-child(2) {
        top: 50px;
        right: 20px;
        animation-delay: 1.5s;
      }
      &:nth-child(3) {
        bottom: 20px;
        left: 60px;
        animation-delay: 3s;
      }
      &:nth-child(4) {
        bottom: 40px;
        right: 25px;
        animation-delay: 4.5s;
      }
      &:nth-child(5) {
        top: 100px;
        right: 60px;
        animation-delay: 5s;
      }
    }
  }

}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(0) translate(-50%, -50%);
  }
  80% {
    opacity: 0.1;
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes jumpOne {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  10% {
    transform: scale(1.3) translate(-50%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes jumpTwo {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  10% {
    transform: scale(1.25) translate(-50%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes jumpThree {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  10% {
    transform: scale(1.2) translate(-50%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes jumpFour {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  10% {
    transform: scale(1.07) translate(-50%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}