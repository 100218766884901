.RequestPreview {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: -24px;
  margin-right: -24px;
  border-bottom: 1px solid #e0e2e4;
  @include mq($until: medium) {
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -24px;
    margin-right: -24px;
  }
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover {
    background-color: transparentize(theme-color(orange), 0.8);
  }

  &__image {
    display: block;
    position: relative;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;    
    border-radius: 50%;
    background-color: lightgray;
    @include mq($until: medium) {
      margin-right: 10px;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      font-family: "object-fit: cover;";
    }
  }
  &__person {
    margin-right: 20px;
    @include mq($until: medium) {
      margin-right: 16px;
    }
  }
  &__name {
    @include build-font('paragraph-small');
    font-weight: bold;
    line-height: 1;
    margin: 0 0 5px 0;
  }
  &__title {
    @include build-font('paragraph-small');
    margin: 0;
    line-height: 1;
    color: gray;
  }
  &__right {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
  }
  &__type {
    display: block;
    width: 24px;
    height: 24px;
    fill: #b9b9b9;
  }
  &__status {
    @include build-font('paragraph-small');
    @include mq($until: xxlarge) {
      @include build-font('paragraph-tiny');
    }
    line-height: 1;
    margin: 0;
    color: gray;
    > span {
      display: block;
      &:first-child {
        margin-bottom: 2px;
        color: black;
      }
    }
    .ignored {
      color: darkred;
    }
  }
  &__date {
    @include build-font('paragraph-small');
    line-height: 1;
    margin: 0;
    margin-left: 20px;
    color: gray;
    @include mq($until: medium) {
      display: none;
    }
  }
  .Modal__surface {
    cursor: auto;
  }
}

.Requests > ul:last-of-type {
  .RequestPreview {
    &:last-child {
      border-bottom: none;
      margin-bottom: -20px;
    }
  }
}
.Requests > ul:first-of-type + .Requests__many {
  margin-top: 20px;
  margin-bottom: -20px;
  &:not(:last-child) {
    margin-top: 0;
    margin-bottom: 0;
    border-top: none;
    border-bottom: 1px solid #e0e2e4;
    background-color: transparentize(theme-color(green), 0.8);
  }
}