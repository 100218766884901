.Greeting {
  min-width: 300px;
  height: auto;
  @include mq($until: large) {
    height: auto;
    p {
      line-height: 1.4;
    }
  }
  p {
    margin: 0;
    a {
      color: theme-color(white);
      font-weight: bold;
      text-decoration: underline;
    }
  }
  span {
    &:last-child {
      margin-left: 8px;
    }
  }
}