.Tooltip {
  position: relative;
  &:hover {
    .Tooltip__text {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &__text {
    position: absolute;
    display: block;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    font-size: rem-calc(12px);
    line-height: 1.2;
    margin: 0;
    background-color: #555;
    color: theme-color(white);
    width: 100px;
    padding: 5px;
    border-radius: 2px;
    transition: opacity 200ms ease;
    &::after {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
  &--top {
    .Tooltip__text {
      right: 50%;
      margin-right: -50px;
      top: calc(-100% - 10px);
      &::after {
        bottom: -10px;
        left: calc(50% - 5px);
      }
    }
  }
  &--bottom {
    .Tooltip__text {
      bottom: -100%;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        top: 0px;
        left: calc(50% - 5px);
        transform: rotate(180deg) translateY(10px);
      }
    }
  }
  &--right {
    .Tooltip__text {
      right: -105px;
      top: 50%;
      transform: translateY(-50%);
      &::after {
        top: 50%;
        left: -10px;
        transform: rotate(-90deg) translateX(5px);
      }
    }
  }
  &--left {
    .Tooltip__text {
      left: -105px;
      top: 50%;
      transform: translateY(-50%);
      &::after {
        top: 50%;
        right: -10px;
        transform: rotate(-90deg) translateX(5px);
      }
    }
  }
}